import {mapState} from "vuex";

export default {
    data() {
        return {
            single: {},
            fields: {},
            model: '',
            content_ready:false,
            single_mode:true,
        }
    },
    filters:{
        good_number(value,precision){
            if (!precision) precision = 0;
            let val = value;
            if (val) {
                val = (val+'').replace(/,/g,"");
            } else {
                val = 0;
            }
            val = Number(val);
            if (val === 0 ) return '';
            val = (isNaN(val) ? '' : val.toFixed(precision) + '').split('.');
            val[0] = val[0].replace(/\B(?=(?:\d{3})+$)/g, ",");
            return val.join('.');
        },
    },
    computed: {
        ...mapState(['process', 'user']),
        editRules() {
            let rules = {};
            for (let x in this.fields) {
                const field = this.fields[x];
                if (field.validation) {
                    rules[x] = [];
                    field.validation.forEach(item => {
                        const trigger = ['text', 'textarea', 'number'].includes(item.type) ? 'blur' : 'change';
                        if (item === 'required') {
                            rules[x].push({
                                required: true,
                                message: this.english ? ((field.title_en || field.title) + " is required!") : ('请输入' + field.title),
                                trigger: trigger
                            });
                        }
                        if (item.indexOf('min:') === 0 || item.indexOf('max:') === 0) {
                            let cmd = item.split(':');
                            if (cmd.length > 1) {
                                let v = {
                                    message: (cmd[0] === 'min' ? ('最小') : ('最大')) + cmd[1] + ('个字'),
                                    trigger: 'blur'
                                };
                                v[cmd[0]] = parseInt(cmd[1]);
                                rules[x].push(v);
                            }
                        }
                        if (item.indexOf('email') === 0) {
                            rules[x].push({
                                type: 'email',
                                message: ('请输入正确的邮箱格式'),
                                trigger: trigger
                            });
                        }
                    });
                }
            }
            return rules;
        },
    },
    methods: {
        handleSubmit(next){
            let vm = this;
            this.$refs['edit_form'].validate(valid => {
                if (valid) {
                    vm.$api(this.model+'.Edit', vm.single, true).then(() => {
                        if (typeof next === 'function') {
                          next();
                        } else {
                            vm.$notify.success('数据已经成功提交!');
                            if (vm.single_mode) {
                                vm.$router.go(-1);
                            } else {
                                this.edit_mode = false;
                            }
                        }
                    })
                } else {
                    vm.$notify({
                        title: '错误',
                        message: '请按照指示填写正确的信息',
                        type: 'error'
                    });
                    return false;
                }
            });
        },
        processData() {
        },
    },
    mounted() {
        if (this.single_mode) {
            let id = this.$route.params['id'];
            let copy = (this.$route.fullPath.includes('/copy/')) ? 1 : 0;
            this.$api(this.model + '.Get', Object.assign({}, this.$route.query, {id: id, copy: copy}), 1).then(res => {
                this.$set(this, 'single', res.data.data.data);
                this.$set(this, 'fields', res.data.data.fields);
                this.processData();
                this.content_ready = true;
                //if (this.single.id === 0)
                this.edit_mode = true;
            })
        }
    }
}
